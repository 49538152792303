.react-daterange-picker {
    display: inline-flex;
    position: relative;
    @apply w-full;
    @apply mt-1;
  }
  .react-daterange-picker,
  .react-daterange-picker *,
  .react-daterange-picker *:before,
  .react-daterange-picker *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  .react-daterange-picker--disabled {
    background-color: #f0f0f0;
    color: #6d6d6d;
  }
  .react-daterange-picker__wrapper {
    @apply appearance-none;
    @apply rounded-none;
    @apply relative;
    @apply block;
    @apply w-full;
    @apply px-3;
    @apply py-2;
    @apply border;
    @apply bg-white;
    @apply border-gray-300;
    @apply placeholder-gray-500;
    @apply text-gray-900;
    @apply rounded-md;
    @apply focus:outline-none;
    @apply focus:ring-blue-500;
    @apply focus:border-blue-500;
    @apply focus:z-10;
    @apply sm:text-sm;
    @apply justify-between;
    display: flex;
    flex-grow: 1;
    flex-shrink: 0;
  }
  .react-daterange-picker__inputGroup {
    min-width: calc((4px * 3) + 0.54em * 8 + 0.5em * 2);
    padding: 0 2px;
    box-sizing: content-box;
  }
  .react-daterange-picker__inputGroup > input[name="daterange_to"],.react-daterange-picker__inputGroup > input[name="daterange_from"]{
    display: none;
  }
  .react-daterange-picker__clear-button{
    margin-left: auto;
  }
  .react-daterange-picker__inputGroup__divider {
    padding: 1px 0;
    white-space: pre;
  }
  .react-daterange-picker__inputGroup__input {
    height: 100%;
    position: relative;
    padding: 0 1px;
    border: 0;
    background: none;
    font: inherit;
    box-sizing: content-box;
    -moz-appearance: textfield;
  }
  .react-daterange-picker__inputGroup__input::-webkit-outer-spin-button,
  .react-daterange-picker__inputGroup__input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  .react-daterange-picker__inputGroup__input:invalid {
    background: rgba(255, 0, 0, 0.1);
  }
  .react-daterange-picker__inputGroup__input--hasLeadingZero {
    margin-left: -0.54em;
    padding-left: calc(1px + 0.54em);
  }
  .react-daterange-picker__button {
    border: 0;
    background: transparent;
    padding: 0 6px;
  }
  .react-daterange-picker__button:enabled {
    cursor: pointer;
  }
  .react-daterange-picker__button:enabled:hover .react-daterange-picker__button__icon,
  .react-daterange-picker__button:enabled:focus .react-daterange-picker__button__icon {
    stroke: #0078d7;
  }
  .react-daterange-picker__button:disabled .react-daterange-picker__button__icon {
    stroke: #6d6d6d;
  }
  .react-daterange-picker__button svg {
    display: inherit;
  }
  .react-daterange-picker__calendar {
    width: 350px;
    max-width: 100vw;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1;
    
    @apply isolate;
    @apply mt-2;
    @apply rounded-lg;
    @apply bg-white;
    @apply text-sm;
    @apply shadow;
    @apply ring-1;
    @apply ring-gray-200;
  }
  .react-daterange-picker__calendar--closed {
    display: none;
  }
  .react-daterange-picker__calendar .react-calendar {
    @apply rounded-lg;
    overflow: hidden;
  }
  .react-daterange-picker__calendar .react-calendar .react-calendar__navigation {
    @apply flex;
    @apply justify-between;
    @apply items-center;
    @apply text-white;
    @apply p-2;
    @apply bg-red-600;
  }
  .react-daterange-picker__calendar .react-calendar .react-calendar__navigation .react-calendar__navigation__label{
    @apply grow-0 !important;
    @apply font-semibold;
    @apply rounded-lg;
    @apply py-1;
    @apply px-4;
  }
  .react-daterange-picker__calendar .react-calendar .react-calendar__navigation .react-calendar__navigation__label:hover{
    @apply bg-red-800;
  }
  .react-calendar__navigation .react-calendar__navigation__arrow.react-calendar__navigation__prev-button {
    @apply px-2;
    @apply font-semibold;
    @apply rounded-lg;
    @apply py-1;
  }
  .react-calendar__navigation .react-calendar__navigation__arrow.react-calendar__navigation__prev-button:hover {
    @apply bg-red-800;
  }
  .react-calendar__navigation .react-calendar__navigation__arrow.react-calendar__navigation__next-button {
    @apply px-2;
    @apply font-semibold;
    @apply rounded-lg;
    @apply py-1;
  }
  .react-calendar__navigation .react-calendar__navigation__arrow.react-calendar__navigation__next-button:hover {
    @apply bg-red-800;
  }
  .react-calendar__navigation .react-calendar__navigation__arrow.react-calendar__navigation__prev2-button {
    display: none;
  }
  .react-calendar__navigation .react-calendar__navigation__arrow.react-calendar__navigation__next2-button {
    display: none;
  }
  .react-calendar__month-view__weekdays {
    @apply grid;
    @apply grid-cols-7;
    @apply text-xs;
    @apply leading-6;
    @apply text-white;
    @apply bg-red-600;
  }
  .react-calendar__month-view__weekdays .react-calendar__month-view__weekdays__weekday {
    @apply flex;
    @apply  justify-center;
    text-transform: uppercase;
    text-decoration: none;
  }
  .react-calendar__month-view__weekdays .react-calendar__month-view__weekdays__weekday abbr{
    text-decoration: none;
  }
  .react-calendar__tile.react-calendar__decade-view__years__year{
    @apply py-1.5;
    @apply hover:bg-gray-200;
    @apply focus:z-10;
    @apply border-[0.5px];
    @apply bg-white;
    @apply text-gray-900;
  }
  .react-calendar__tile.react-calendar__decade-view__years__year.react-calendar__tile--hasActive{
    @apply bg-indigo-200;
    @apply font-semibold;
  }
  .react-calendar__tile.react-calendar__year-view__months__month{
    @apply py-1.5;
    @apply hover:bg-gray-200;
    @apply focus:z-10;
    @apply border-[0.5px];
    @apply bg-white;
    @apply text-gray-900;
  }
  .react-calendar__tile.react-calendar__year-view__months__month abbr{
    text-transform: capitalize;
  }
  .react-calendar__tile.react-calendar__year-view__months__month.react-calendar__tile--hasActive{
    @apply bg-indigo-200;
    @apply font-semibold;
  }
  .react-calendar__month-view__days .react-calendar__tile.react-calendar__month-view__days__day{
    @apply py-1.5;
    @apply hover:bg-gray-200;
    @apply focus:z-10;
    @apply border-[0.5px];
    @apply bg-white;
    @apply text-gray-900;
  }
  
  .react-calendar__month-view__days .react-calendar__tile.react-calendar__month-view__days__day abbr {
    @apply mx-auto;
    @apply flex;
    @apply h-3;
    @apply w-3;
    @apply items-center;
    @apply justify-center;
    @apply rounded-full;
  }
  .react-calendar__month-view__days .react-calendar__tile.react-calendar__month-view__days__day.react-calendar__month-view__days__day--neighboringMonth{
    @apply bg-gray-100;
    @apply text-gray-400;
  }
  .react-calendar__month-view__days .react-calendar__tile.react-calendar__month-view__days__day.react-calendar__tile--now {
    @apply text-indigo-600;
  }
  .react-calendar__month-view__days .react-calendar__tile.react-calendar__month-view__days__day.react-calendar__tile--hover {
    @apply bg-gray-200;
  }
  .react-calendar__month-view__days .react-calendar__tile.react-calendar__month-view__days__day.react-calendar__tile--hoverEnd {
    @apply bg-gray-300;
  }
  .react-calendar__month-view__days .react-calendar__tile.react-calendar__month-view__days__day.react-calendar__tile--active {
    @apply font-semibold text-white;
    @apply bg-gray-900;
  }
  .react-calendar__month-view__days .react-calendar__tile.react-calendar__month-view__days__day.react-calendar__tile--active abbr {
    @apply mx-auto;
    @apply flex;
    @apply h-3;
    @apply w-3;
    @apply items-center;
    @apply justify-center;
    @apply rounded-full;
  }
  .react-calendar__month-view__days .react-calendar__tile.react-calendar__month-view__days__day.react-calendar__tile--hasActive {
    @apply font-semibold text-white;
    @apply bg-gray-900;
  }
  .react-calendar__month-view__days .react-calendar__tile.react-calendar__month-view__days__day.react-calendar__tile--hasActive abbr {
    @apply mx-auto;
    @apply flex;
    @apply h-3;
    @apply w-3;
    @apply items-center;
    @apply justify-center;
    @apply rounded-full;
  }