@tailwind base;
@tailwind components;
@tailwind utilities;

@media print 
{
  @page {
    size: letter;
    margin: 1.54cm
  }
}
.red-li::marker {
  color: #b91c1c;
}

.star-li {
  padding-left: 1em;
  text-indent: -1.2em;
}

.star-li:before {
  content: ' \2605';
  font-size: 0.7em;
  color: #b91c1c;
  padding-right: 1rem;
}